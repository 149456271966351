#file-selector {
    margin: 20px;
    display: flex;
    justify-content: center;
}

#file-selector-form {
    height: 26vh;
    width: 30vw;
    max-width: 100%;
    text-align: center;
    position: relative;
    border-style: dashed;
    transition: 0.25s ease;
    border-color: #282c34;
    background-color: #f8fafc;
    border-width: 4px;
    border-radius: 1rem;
}

#file-selector-form:hover {
    transition: 0.25s ease;
    background-color: #21cea3;
}

#file-selector-input {
    display: none;
}

#drag-drop-label {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    border-width: 2px;
    border-radius: 1rem;
    font-size: 28px;
}

#click-label {
    font-size: 16px;
}

#file-selector-button {
    position: absolute;
    height: 100%;
    width: 100%;
    cursor: pointer;
    border: none;
    background-color: transparent;
    
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border-radius: 1rem;
    top: -4px;
    left: -4px;
}

/* #file-selector-button:after {
    content: "";
    background: #90EE90;
    position: absolute;
    width: 100%;
    height: 100%;
    width: 0;
    height: 0;

    top: 50%;
    left: 50%;
    top: var(--mouse-y);
    left: var(--mouse-x);
    padding-top: 50%;
    padding-left: 50%;
    
    opacity: 0;
    transition: all 0.8s;
    transform-style: flat;
    transform: translate3d(-50%,-50%,0);
    background: rgba(white,.1);
    border-radius: 100%;
    transition: width .3s ease, height .3s ease;
  }
  
#file-selector-button:active:after {
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: 1s
  } */

#drag-file-element {
    position: absolute;
    background-color: #21cea3;
    transition: 0.25s ease;
    opacity: 0.35;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border-radius: 1rem;
    top: -4px;
    left: -4px;
    bottom: 0px;
    right: 0px;
}
