#home {
    height: 100%;
    padding: 10px;
    overflow: auto;
}

#home-control-bar {
    justify-content: space-between;
}

.control-container {
    width: 33%;
    justify-content: center;
    align-items: center;
}

#sort-select-box {
    width: 33%;
}

.link button {
    height: 45px;
    width: 200px;

    font-size: 26px;
    transition-duration: 0.1s;
    border: none;
    border-radius: 8px;
}

.link button:hover {
    transition-duration: 0.1s;
    background-color: #23aca0;
}

.clip-rows {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.clip-card-container {
    margin: 10px 10px;
    height: 400px;
    width: 400px;
    
    overflow: hidden;
    border-radius: 4px;
}

.clip-link, .clip-card {
    position: relative;
    height: 100%;
    width: 100%;

    background-color: black;
}

.clip-card img {
    z-index: 1;
    position: absolute;

    left: 0;

    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.clip-card-body {
    z-index: 2;
    position: absolute;
    
    height: 20%;
    width: 100%;
    top: 0;

    background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 80%, rgba(0, 0, 0, 0) 100%);
    
    display: flex;
    flex-direction: row;

    font-size: 22px;
}

.clip-title {
    position: absolute;
    margin: 5px 15px;

    top: 0;
    left: 0;
}

.time-since-upload {
    z-index: 2;
    position: absolute;
    
    height: 30px;
    width: 100px;
    left: -3px;
    bottom: -3px;

    background-color: black;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    color: white;
    text-align: center;
    font-size: 10px;
}

.clip-duration {
    z-index: 2;
    position: absolute;
    
    height: 30px;
    width: 70px;
    right: -3px;
    bottom: -3px;

    background-color: black;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    color: white;
    text-align: center;
    font-size: 16px;
}
