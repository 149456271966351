#upload-progress {
    height: 100%;
    align-items: center;
}

#upload-progress-subchild {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 600px;
    width: 60%;
    min-width: 620px;

    background-color: #23aca0;
    color: black;
}

#info-line {
    margin: 10px;
}

#upload-log-container {
    width: calc(100% - 40px);

    flex: 1;

    margin: 0 10px;
    border-radius: 2px;

    background-color: #418378;
    overflow-y: auto;
}

#upload-log-lines-container {
    display: flex;
    flex-direction: column;
}

.upload-log-line-icon {
    width: 40px;
    display: flex;
    justify-content: center;
}

.upload-log-line {
    margin: 5px;
    height: 24px;
    color: rgb(59, 59, 59);

    float: left;
    display: flex;
    align-items: center;
    text-align: left;
}

#upload-log-current-line {
    bottom: 0%;
    color: black;
    font-weight: bold;
}

#wrapup-buttons {
    width: 640px;
    display: flex;
}

.wrapup-button-link {
    width: 300px;
    height: 50px;
    margin: 10px;

    border: none;
    border-radius: 8px;
}

.wrapup-button {
    height: 101%;
    width: 101%;
    color: black;
    
    font-size: 26px;
    transition-duration: 0.1s;
    border: none;
    border-radius: 8px;
}

#go-clip:hover:enabled, #go-home:hover:enabled {
    background-color: #418378
}

.wrapup-button:disabled{
    border: 1px solid #999999;
    background-color: #c4c4c4;
    color: #858585;
}
