#player {
    height: 100%;
    display: flex;
    flex-direction: row;
}


#clip-container {
    width: calc(75% - 40px);

    margin: 10px 10px;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
}

#title {
    padding: 0 0 0 20px;
    text-align: left;
}

#sidebar {
    height: calc(100% - 20px);
    width: 25%;

    margin: 10px 10px;

    display: flex;
    flex-direction: column;
}

#details-container {
    max-height: 30%;
    padding: 10px;
    color: black;

    border-radius: 4px;

    font-size: 16px;
}

#description-container {
    max-height: 100px;
    padding: 10px;
    color: black;
    white-space: pre-line;

    border-radius: 4px;

    text-align: left;
    font-size: 14px;

    overflow: auto;
}

#comments-container {
    min-height: calc(70% - 50px);
    padding: 10px;
    margin: 10px 0 0 0;
    color: black;

    flex: 1;
    border-radius: 4px;

    text-align: center;
    font-size: 16px;
}

@media only screen and (max-width: 1024px) {
    #player {
        flex-direction: column;
    }
    
    #clip-container {
        width: calc(100% - 20px);
    }

    #sidebar {
        width: calc(100% - 20px);
    }
    #details-container {
        max-height: 100%;
    }
}
