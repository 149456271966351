#previewer {
    height: 100%;
    display: flex;
    flex-direction: row;
}

#video-component {
    width: calc(75% - 40px); /* overcompensate for width of clip-details-form */

    margin: 10px 10px;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
}

#clip-details-form {
    height: calc(100% - 20px);
    width: 25%;

    margin: 10px 10px;

    display: flex;
    flex-direction: column;
    
    font-size: 20px;
}

#trimmer {
    grid-column: 1;
    grid-row: 1;
}
