#uploader {
    height: 100%;
}

button {
    color: #272727;
    border: 0px;
    border-radius: 2px;
    cursor: pointer;
    width: max-content;
    transition: 0.25s ease;
}

button:hover:enabled {
    background-color: #23aca0;
}

button:hover:disabled {
    cursor: default ;
}
