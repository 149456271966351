#comments-box {
    height: 100%;
    margin: 10px 0 0 0;
    border-radius: 4px;

    overflow: auto;
}

#add-comment-container {
    height: 140px;
    min-height: 100px;
    width: calc(100% - 8px);
    
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px;
}

#add-comment-button {
    height: 70px;
    width: 70%;
    border-radius: 8px;

    margin: 10px 0;

    background-color: #23aca0;
    color: #374153;

    font-size: 32px;
}

#add-comment-button:hover:enabled {
    transition-duration: 0.1s;
    background-color: #21cea3;
    color: #37635a;
}

#adding-comment-container {
    height: 100%;
    width: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#comment-box {
    width: 95%;
    height: calc(70% - 6px);
    margin-block: 3px;
}

#adding-comment-control {
    width: 95%;
    height: calc(30% - 6px);
    display: flex;
    flex-direction: row;
    justify-content: right;

    padding: 3px;
}

#adding-comment-control button {
    width: 20%;
    margin: 0 4px;
}

.comment {
    color: #eee6ed;
}

.comment .header {
    padding: 4px 8px;
    font-weight: 500;
}

.comment .options {
    height: 22px;
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.comment .options button {
    height: 22px;
    background-color: #37635a;
    font-size: 20px;
}

.comment .options button:hover {
    background-color: #37635a;
    color: #67b1a8;
    transition-duration: 0.1s;
}

.comment .gear {
    width: 22px;
    padding: 0;
    margin: 0 4px;
}

.comment .options-buttons {
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.comment .close {
    width: 22px;
    padding: 0;
    margin: 0 4px 0 0;
}

.comment .posted-at {
    margin-left: 10px;
    font-weight: 200;
}

.comment .separator {
    margin: 2px 12px;
    border-top: 0px;
}

.comment .text {
    padding: 8px 20px 10px 20px;
    text-align: left;
    white-space: pre-line;
    font-weight: 350;
    color: #eee6ed
}
